.SURVEY {
    max-width: 1000px;
    width: 100%;
    /*display: block;*/
    margin-left: auto;
    margin-right: auto;
}

.QuestionElement {
    margin-top: 50px;
    margin-bottom: 50px;
}
.QuestionElement .Question {
    font-weight: bold;
    font-size: larger;
    outline: none;
    margin-bottom: 20px;
}

.QuestionMultipleChoice {
    border: 0;
    padding-left: 0;
}
.QuestionMultipleChoice .answers {
    margin-left: 23px !important;
    width: calc(100% - 23px);
}
.QuestionMultipleChoice .answers input {
    margin-left: -23px;
}

.QuestionAddList * {
    max-width: 600px;
}
.QuestionAddListEntry {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}
.QuestionAddListEntry * {
    float: left;
}
.QuestionAddListEntry button {
    float: right !important;
    right: 0;
    margin-right: 0;
    height: 26px;
}
.QuestionAddList input:focus {
    border: 3px solid currentColor;
    outline: none;
    margin-top: 2px;
    margin-bottom: -4px;
}
.QuestionAddList .TextInput {
    float: left;
    margin-left: 0px;
}
.QuestionAddList > button {
    margin-left: 20px;
}

.QuestionElement .answers {
    font-size: smaller;
    margin-left: 20px;
}
.QuestionElement .answers .option {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.answers input {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
}
.notchoosen {
    opacity: 40%;
}

.CartesianFrame {
    width: 100%;
    Height: 500px;
}

.CartesianFrame .CartesianFigureSetup {
    position: relative;
    width: 100px;
    height: 500px;
    background-color: transparent;
    margin-left: 20px;
    margin-right: 30px;
    border: 2px solid currentColor;
    border-radius: 5px;
    z-index: 1;
    padding: 0;
}
.CartesianFrame .CartesianBoard {
    visibility: visible;
    position: relative;
    top: -504px;
    left: 150px;
    width: 700px;
    height: 100%;
    background-color: transparent;
    margin-left: 20px;
    border: 2px solid currentColor;
    border-radius: 5px;
    z-index: 0;
}

button {
    background-color: rgba(51,255,51,.7);
    color: black;
}

.figure {
    fill: currentColor;
    width: 40px;
    height: 40px;
    position: absolute;
    cursor: grab;
    z-index: 1;
    top: 20px;
    left: 30px;
}
.figure:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
.notallow {
    cursor: no-drop;
}

.languageSelect {
    margin-top: -20px;
    float: right;
    margin-right: 20px;
    margin-bottom: 50px;
}
select {
    height: 30px;
    background-color: transparent;
    color: currentColor;
    outline: none;
    border: 0;
}
select * {
    background-color: black;
    border-radius: 0;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    height: 40px;
    height: 34px;
}